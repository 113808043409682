@import url('https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap');
* {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    font-family: 'Roboto', sans-serif;
    outline: none;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: '#345555';
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.logo {
    margin: 10px;
    height: 50px;
    box-sizing: border-box;
}

.ant-layout-header {
    background-color: #fff;
    box-shadow: 0 0 4px #eee;
}

.box-container {
    margin-top: 15px;
    padding: 20px;
    min-height: 400px;
    background-color: #fff;
    box-shadow: 0 0 4px #e7e7e7;
    border-radius: 4px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    list-style: none;
}

/* Correção do padding do submenu */

.ant-menu-item {
    padding-left: 15px !important;
}

.top-header {
    max-width: 100%;
    padding: 10px 5px;
    margin: 0;
}

/*formulário de login */

.form-login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 315px;
    margin: 100px auto;
    padding: 20px;
    border: none;
    border-radius: 8px;
    box-sizing: border-box;
    background: #1890ff;
    /* box-shadow:0px 15px 30px #65CAFD; */
    align-self: center;
}

.login-logo {
    height: 50px;
    align-self: center;
}

.titulo {
    align-self: center;
    color: #fff !important;
    margin: 10px auto;
}

.input-login {
    padding: 12px;
    border-radius: 4px;
    border: none;
    margin-bottom: 15px;
    font-size: 15px;
    color: #003752;
    font-weight: bold;
}

.input-login::placeholder {
    color: #81BEF7;
}

.button-login {
    padding: 12px;
    border-radius: 4px;
    border: none;
    background: #003752;
    color: #fff;
    font-size: 15px;
    font-weight: bold;
    cursor: pointer;
}

.button-login:hover {
    background: #012435;
    color: #fff;
}

.form-login span {
    color: #FEF101;
    align-self: flex-start;
    margin: 0 0 10px;
    font-weight: bold;
    font-size: 15px;
}

.form-login-mensagem {
    color: #FEF101;
    align-self: flex-start;
    margin: 0 0 10px;
    font-weight: bold;
    font-size: 15px;
}

.ambiente-demonstrativo {
    position: fixed;
    z-index: 50;
    border-radius: 0px 0px 10px 10px;
    font-family: 'Roboto', sans-serif;
    border-color: #cc0000;
    text-align: center;
    border-width: 1px;
    background: #cc0000;
    padding: 0px 15px;
    height: 60px;
    color: #ffffff;
    font-size: 14px;
    margin: 0px auto 0px 8%;
    display: inline-table;
    box-shadow: 0px 0px 20px #ccc;
}

.authPage {
    display: flex;
    transform: "translate(-50%, -50%)";
    width: "100%";
    justify-content: "center";
    align-items: "center";
    position: " fixed";
    top: "0";
    left: "0";
    right: "0";
    bottom: "0";
    display: "flex";
    align-items: "center";
}

#op1 {
    margin-left: 30px;
    padding: 0px;
}