@import url(https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap);
* {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    font-family: 'Roboto', sans-serif;
    outline: none;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: '#345555';
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.logo {
    margin: 10px;
    height: 50px;
    box-sizing: border-box;
}

.ant-layout-header {
    background-color: #fff;
    box-shadow: 0 0 4px #eee;
}

.box-container {
    margin-top: 15px;
    padding: 20px;
    min-height: 400px;
    background-color: #fff;
    box-shadow: 0 0 4px #e7e7e7;
    border-radius: 4px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    list-style: none;
}

/* Correção do padding do submenu */

.ant-menu-item {
    padding-left: 15px !important;
}

.top-header {
    max-width: 100%;
    padding: 10px 5px;
    margin: 0;
}

/*formulário de login */

.form-login {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    max-width: 315px;
    margin: 100px auto;
    padding: 20px;
    border: none;
    border-radius: 8px;
    box-sizing: border-box;
    background: #1890ff;
    /* box-shadow:0px 15px 30px #65CAFD; */
    -webkit-align-self: center;
            align-self: center;
}

.login-logo {
    height: 50px;
    -webkit-align-self: center;
            align-self: center;
}

.titulo {
    -webkit-align-self: center;
            align-self: center;
    color: #fff !important;
    margin: 10px auto;
}

.input-login {
    padding: 12px;
    border-radius: 4px;
    border: none;
    margin-bottom: 15px;
    font-size: 15px;
    color: #003752;
    font-weight: bold;
}

.input-login::-webkit-input-placeholder {
    color: #81BEF7;
}

.input-login:-ms-input-placeholder {
    color: #81BEF7;
}

.input-login::placeholder {
    color: #81BEF7;
}

.button-login {
    padding: 12px;
    border-radius: 4px;
    border: none;
    background: #003752;
    color: #fff;
    font-size: 15px;
    font-weight: bold;
    cursor: pointer;
}

.button-login:hover {
    background: #012435;
    color: #fff;
}

.form-login span {
    color: #FEF101;
    -webkit-align-self: flex-start;
            align-self: flex-start;
    margin: 0 0 10px;
    font-weight: bold;
    font-size: 15px;
}

.form-login-mensagem {
    color: #FEF101;
    -webkit-align-self: flex-start;
            align-self: flex-start;
    margin: 0 0 10px;
    font-weight: bold;
    font-size: 15px;
}

.ambiente-demonstrativo {
    position: fixed;
    z-index: 50;
    border-radius: 0px 0px 10px 10px;
    font-family: 'Roboto', sans-serif;
    border-color: #cc0000;
    text-align: center;
    border-width: 1px;
    background: #cc0000;
    padding: 0px 15px;
    height: 60px;
    color: #ffffff;
    font-size: 14px;
    margin: 0px auto 0px 8%;
    display: inline-table;
    box-shadow: 0px 0px 20px #ccc;
}

.authPage {
    display: -webkit-flex;
    display: flex;
    -webkit-transform: "translate(-50%, -50%)";
            transform: "translate(-50%, -50%)";
    width: "100%";
    -webkit-justify-content: "center";
            justify-content: "center";
    -webkit-align-items: "center";
            align-items: "center";
    position: " fixed";
    top: "0";
    left: "0";
    right: "0";
    bottom: "0";
    display: "flex";
    align-items: "center";
}

#op1 {
    margin-left: 30px;
    padding: 0px;
}
.card-container > .ant-tabs-card > .ant-tabs-content {
    height: 120px;
    margin-top: -16px;
    background: #0f0;
  }
  
  .card-container > .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane {
    background: #fff;
    padding: 16px;
  }
  
  .card-container > .ant-tabs-card > .ant-tabs-bar {
    border-color: #fff;
  }
  
  .card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
    border-color: transparent;
    background: transparent;
  }
  
  .card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
    border-color: #fff;
    background: #fff;
  }

  .divTotais{
    text-align: center;
    width: 150px;
    color: white;
    border-radius: 12px;
    margin-left: 5px;
    margin-top: 0px;
    margin-bottom: 10px;
    background: "transparent";
  }
.react-grid-layout {
    position: relative;
    transition: height 200ms ease;
  }
  .react-grid-item {
    transition: all 200ms ease;
    transition-property: left, top;
  }
  .react-grid-item.cssTransforms {
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
  }
  .react-grid-item.resizing {
    z-index: 1;
    will-change: width, height;
  }
  
  .react-grid-item.react-draggable-dragging {
    transition: none;
    z-index: 3;
    will-change: transform;
  }
  
  .react-grid-item.react-grid-placeholder {
    background: red;
    opacity: 0.2;
    transition-duration: 100ms;
    z-index: 2;
    -webkit-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
  
  .react-grid-item:hover  > .react-resizable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    bottom: 0;
    right: 0;
    background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pg08IS0tIEdlbmVyYXRvcjogQWRvYmUgRmlyZXdvcmtzIENTNiwgRXhwb3J0IFNWRyBFeHRlbnNpb24gYnkgQWFyb24gQmVhbGwgKGh0dHA6Ly9maXJld29ya3MuYWJlYWxsLmNvbSkgLiBWZXJzaW9uOiAwLjYuMSAgLS0+DTwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DTxzdmcgaWQ9IlVudGl0bGVkLVBhZ2UlMjAxIiB2aWV3Qm94PSIwIDAgNiA2IiBzdHlsZT0iYmFja2dyb3VuZC1jb2xvcjojZmZmZmZmMDAiIHZlcnNpb249IjEuMSINCXhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbDpzcGFjZT0icHJlc2VydmUiDQl4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjZweCIgaGVpZ2h0PSI2cHgiDT4NCTxnIG9wYWNpdHk9IjAuMzAyIj4NCQk8cGF0aCBkPSJNIDYgNiBMIDAgNiBMIDAgNC4yIEwgNCA0LjIgTCA0LjIgNC4yIEwgNC4yIDAgTCA2IDAgTCA2IDYgTCA2IDYgWiIgZmlsbD0iIzAwMDAwMCIvPg0JPC9nPg08L3N2Zz4=");
    background-position: bottom right;
    padding: 0 3px 3px 0;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    cursor: se-resize;
  }
  

  /* |||||||||||||||||||||||||||||||||||||||||||||||||||| */
  /* |||||||||||||||||||||||||||||||||||||||||||||||||||| */
  /* |||||||||||||||||||||||||||||||||||||||||||||||||||| */
  /* |||||||||||||||||||||||||||||||||||||||||||||||||||| */


  .react-grid-layout {
    background: #eee;
  }
  .layoutJSON {
    background: #ddd;
    border: 1px solid rgb(0, 0, 0);
    margin-top: 10px;
    padding: 10px;
  }
  .columns {
    -webkit-columns: 120px;
    columns: 120px;
  }
  .react-grid-item {
    box-sizing: border-box;
  }
  .react-grid-item:not(.react-grid-placeholder) {
    background: #ccc;
    border: 1px solid black;
  }
  .react-grid-item.resizing {
    opacity: 0.9;
  }
  .react-grid-item.static {
    background: #cce;
  }
  .react-grid-item .text {
    font-size: 24px;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 24px;
  }
  .react-grid-item .minMax {
    font-size: 12px;
  }
  .react-grid-item .add {
    cursor: pointer;
  }
  .react-grid-dragHandleExample {
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -webkit-grab;
  }
  li b {
    font-size: 19px;
    line-height: 14px;
  }
  

  
